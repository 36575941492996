<template>
  <div class="m-pdf">
    <div id="pdf-content"></div>
  </div>
</template>
 
<script>
import { previewAttest } from '@/api'
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
export default {
  props: {},
  data () {
    return {
      pdfUrl: '',
      pageNum: 1,
      id: null
    }
  },

  methods: {
    async getprotocolGet () {
      // protocolGet().then((res)=>{
      //   if(res.code==200){
      // this.pdfUrl = '/pdf.pdf'//获取到的协议展示
      const res = await previewAttest([this.id])
      this.$emit('url',res.data)
      // console.log('res :>> ', res);
      this.pdfUrl = `https://record.terabits.cn${res.data}`
      // console.log('this.pdfUrl :>> ', this.pdfUrl);
      this.pdfh5 = new Pdfh5("#pdf-content", {
        pdfurl: this.pdfUrl
        // scrollEnable: false
        // 设置每一页pdf上的水印
        // logo: { src: require("@/assets/images/bus/icon_head@2x.png"), x: 420, y: 700, width: 120, height: 120 },
      })
      // this.pdfh5.scrollEnable(true); // 允许pdf滚动
      // 监听pdf准备开始渲染，此时可以拿到pdf总页数
      // this.pdfh5.on("ready", function () {
      // console.log("总页数:" + this.totalNum);
      // });
      // 监听pdf加载完成事件，加载失败、渲染成功都会触发
      this.pdfh5.on("complete", (status, msg, time) => {
        console.log("状态:" + status + "，信息:" + msg + "，耗时:" + time + "毫秒");
      })
    }

  },
  created () {
    this.id = Number(this.$route.params.id)
    // console.log('this.$route', this.$route)
  },
  mounted () {
    this.getprotocolGet()
  },
}
</script>
<style lang="scss" scoped>
.m-pdf {
  width: 100vw;
  height: 80vh;
  // position: relative;
  // top: 0;
  // bottom: 0;
  // right: 0;
  // left: 0;
}
</style>
